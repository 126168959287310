import React, { useState, useContext, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import JSONCompare from 'json-deep-compare' // Use the npm package instead of local implementation
// import { compareJSON } from '../../utils/json-deep-compare'
import { Button, ButtonLink } from '../../components/Button'
import ThemeContext from '../../context/ThemeContext'
import config from '../../../data/SiteConfig'

// Lazy loaded components
const Layout = lazy(() => import('../../layout'))

// Attribution component for the library
function LibraryAttribution() {
  return (
    <div className={'margin-top-1 margin-bottom-2 position-relative'}>
      <div>
        <h3 className="margin-t-0">Powered by json-deep-compare</h3>
        <p>
          This tool is powered by the{' '}
          <ButtonLink
            simpleLink
            href="https://www.npmjs.com/package/json-deep-compare"
            rel="external noopener noreferrer"
            target="_blank"
            className="b"
            title="json-deep-compare NPM Library"
          >
            json-deep-compare
          </ButtonLink>{' '}
          library, a powerful and flexible solution for comparing JSON objects with advanced
          options.
        </p>

        <div className="flex margin-top-1" style={{ gap: '1rem', flexWrap: 'wrap' }}>
          <ButtonLink
            href="https://github.com/ashmeetsehgal/json-deep-compare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="flex" style={{ alignItems: 'center', gap: '0.5rem' }}>
              <svg height="20" width="20" viewBox="0 0 16 16" version="1.1">
                <path
                  fillRule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                />
              </svg>
              Star on GitHub
            </span>
          </ButtonLink>

          <ButtonLink
            href="https://www.npmjs.com/package/json-deep-compare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="flex" style={{ alignItems: 'center', gap: '0.5rem' }}>
              <svg height="20" width="20" viewBox="0 0 576 512">
                <path d="M288 288h-32v-64h32v64zm288-128v192H288v32H160v-32H0V160h576zm-416 32H32v128h64v-96h32v96h32V192zm160 0H192v160h64v-32h64V192zm224 0H352v128h64v-96h32v96h32v-96h32v96h32V192z"/>
              </svg>
              NPM Package
            </span>
          </ButtonLink>
        </div>

        <blockquote className="flex flex-direction-column">
          <p className="i margin-0">
          &quot;Feel free to check this powerful library out and in case you want to contribute or if
            you like it, give it a star on GitHub!&quot;
          </p>
          <span
            className="b"
            style={{
              alignSelf: 'self-end',
            }}
          >
            ~ Ashmeet Sehgal, Library Author
          </span>
        </blockquote>
      </div>
    </div>
  )
}

function JsonCompareTool() {
  const theme = useContext(ThemeContext)
  const isDark = theme?.dark || false
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768

  const [json1, setJson1] = useState('')
  const [json2, setJson2] = useState('')
  const [strictTypeChecking, setStrictTypeChecking] = useState(true)
  const [ignoreKeys, setIgnoreKeys] = useState('')
  const [equivalentValues, setEquivalentValues] = useState('')
  const [ignoreExtraKeys, setIgnoreExtraKeys] = useState(false)
  const [useRegex, setUseRegex] = useState(false)
  const [regexPatterns, setRegexPatterns] = useState('')
  const [results, setResults] = useState(null)
  const [compareError, setCompareError] = useState(null)
  const [showResults, setShowResults] = useState(false)

  const handleCompare = () => {
    try {
      const parsedJson1 = JSON.parse(json1)
      const parsedJson2 = JSON.parse(json2)

      // Reset any previous errors
      setCompareError(null)

      try {
        // Create the validator with the specified options
        const apiValidator = new JSONCompare({
          // If ignoreKeys is specified, convert to the format expected by the library
          ignoredKeys: ignoreKeys.trim() ? ignoreKeys.split(',').map((key) => key.trim()) : [],

          // If equivalentValues is specified, parse and use it
          equivalentValues: equivalentValues.trim() ? JSON.parse(equivalentValues) : {},

          // Use the ignoreExtraKeys setting
          ignoreExtraKeys,

          // Use the strictTypeChecking setting (inverse of strictTypes in the example)
          strictTypes: strictTypeChecking,

          // If regex validation is enabled, parse and use regexPatterns
          regexChecks: useRegex && regexPatterns.trim() ? JSON.parse(regexPatterns) : {},

          // Match keys by name for regex validation
          matchKeysByName: true,
        })

        // Log the comparison parameters for debugging
        // eslint-disable-next-line no-console
        console.log('Compare options:', {
          ignoredKeys: ignoreKeys.trim() ? ignoreKeys.split(',') : [],
          equivalentValues: equivalentValues.trim() ? JSON.parse(equivalentValues) : {},
          ignoreExtraKeys,
          strictTypes: strictTypeChecking,
          regexChecks: useRegex && regexPatterns.trim() ? JSON.parse(regexPatterns) : {},
        })

        // Perform the comparison
        const validationResult = apiValidator.compare(parsedJson1, parsedJson2)
        // eslint-disable-next-line no-console
        console.log('Validation Result:', validationResult)

        if (validationResult) {
          // Transform the result to match our UI expectations
          const transformedResult = {
            matchPercentage: validationResult.summary?.matchPercentage || 0,
            matchedKeys: [],
            unmatchedKeys: [],
            typeMismatches: {},
            regexValidationFailures: {},
          }

          // Process matched keys (not directly provided in the example)
          if (validationResult.matched && validationResult.matched.values) {
            transformedResult.matchedKeys = validationResult.matched.values.map((item) => item.path)
          }

          // Process unmatched keys
          if (validationResult.unmatched && validationResult.unmatched.values) {
            transformedResult.unmatchedKeys = validationResult.unmatched.values.map(
              (item) => item.path,
            )

            // Build type mismatches
            validationResult.unmatched.values.forEach((item) => {
              if (typeof item.expected !== typeof item.actual) {
                transformedResult.typeMismatches[item.path] = {
                  expected: typeof item.expected,
                  actual: typeof item.actual,
                }
              }
            })
          }

          // Process regex validation failures
          if (validationResult.regexChecks && validationResult.regexChecks.failed) {
            validationResult.regexChecks.failed.forEach((item) => {
              transformedResult.regexValidationFailures[item.path] = item.message
            })
          }

          // Store the transformed result
          setResults(transformedResult)
          setShowResults(true)
        } else {
          // Handle empty results
          setCompareError('The comparison library returned an empty result')

          // Create a fallback result with a basic comparison
          const fallbackResult = {
            matchPercentage: 0,
            matchedKeys: [],
            unmatchedKeys: [],
            typeMismatches: {},
          }

          // Attempt basic comparison
          const allKeys1 = new Set(Object.keys(parsedJson1))
          const allKeys2 = new Set(Object.keys(parsedJson2))

          // Find matched keys
          const matched = [...allKeys1].filter(
            (key) =>
              allKeys2.has(key) &&
              JSON.stringify(parsedJson1[key]) === JSON.stringify(parsedJson2[key]),
          )
          fallbackResult.matchedKeys = matched

          // Find unmatched keys
          const unmatched = [
            ...[...allKeys1].filter(
              (key) =>
                !allKeys2.has(key) ||
                JSON.stringify(parsedJson1[key]) !== JSON.stringify(parsedJson2[key]),
            ),
            ...[...allKeys2].filter((key) => !allKeys1.has(key)),
          ]
          fallbackResult.unmatchedKeys = [...new Set(unmatched)]

          // Calculate match percentage
          const totalUniqueKeys = new Set([...allKeys1, ...allKeys2]).size
          fallbackResult.matchPercentage = totalUniqueKeys ? matched.length / totalUniqueKeys : 0

          setResults(fallbackResult)
          // eslint-disable-next-line no-console
          console.log('Using fallback comparison:', fallbackResult)
        }
      } catch (comparisonError) {
        // eslint-disable-next-line no-console
        console.error('Comparison error:', comparisonError)
        const errorMsg = comparisonError.message || 'Unknown error during comparison'
        setCompareError(`Comparison error: ${errorMsg}`)
        // eslint-disable-next-line no-alert
        alert(`Comparison error: ${errorMsg}`)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('General error:', error)
      setCompareError(`Error: ${error.message}`)
      // eslint-disable-next-line no-alert
      alert(`Error: ${error.message}`)
    }
  }

  const handleLoadSample = () => {
    setJson1(
      JSON.stringify(
        {
          user: {
            name: 'Ashmeet Sehgal',
            email: 'ashmeet@ashmeetsehgal.com',
            details: {
              phone: '+91-9876543210',
            },
          },
          products: [
            {
              id: 'PROD-123',
              name: 'Product 1',
            },
          ],
        },
        null,
        2,
      ),
    )

    setJson2(
      JSON.stringify(
        {
          user: {
            name: 'Ashmeet Sehgal',
            email: 'contact@ashmeetsehgal.com',
            details: {
              phone: '+91-9876543210',
            },
          },
          products: [
            {
              id: 'PROD-123',
              name: 'Product 1',
            },
          ],
        },
        null,
        2,
      ),
    )
  }

  const handleCopyResults = () => {
    if (results) {
      navigator.clipboard.writeText(JSON.stringify(results, null, 2))
      // eslint-disable-next-line no-alert
      alert('Results copied to clipboard!')
    }
  }

  const handleDownloadResults = () => {
    if (results) {
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(results, null, 2))}`
      const downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', 'json-comparison-results.json')
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    }
  }

  const renderMatchedKeys = () => {
    if (!results || !results.matchedKeys || results.matchedKeys.length === 0) {
      return <p>No matched keys</p>
    }
    return (
      <ul>
        {results.matchedKeys.map((key) => (
          <li key={key}>
            <span className="green">{key}</span>
          </li>
        ))}
      </ul>
    )
  }

  const renderUnmatchedKeys = () => {
    if (!results || !results.unmatchedKeys || results.unmatchedKeys.length === 0) {
      return <p>No unmatched keys</p>
    }
    return (
      <ul>
        {results.unmatchedKeys.map((key) => (
          <li key={key}>
            <span className="pink">{key}</span>
          </li>
        ))}
      </ul>
    )
  }

  const renderTypeMismatches = () => {
    if (!results || !results.typeMismatches || Object.keys(results.typeMismatches).length === 0) {
      return <p>No type mismatches</p>
    }
    return (
      <ul>
        {Object.entries(results.typeMismatches).map(([key, types]) => (
          <li key={key}>
            <span className="pink">
              {key}: {types.expected} (expected) vs {types.actual} (actual)
            </span>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout
        title="JSON Deep Compare Tool"
        seoDescription="Compare two JSON objects with detailed results"
      >
        <Helmet title={`JSON Deep Compare Tool | ${config.siteTitle}`} />
        <div className="container">
          <h1>JSON Deep Compare Tool</h1>
          <p>
            Compare two JSON objects and get detailed information about their differences. This tool
            uses the json-deep-compare library to perform advanced comparisons with configurable
            options.
          </p>

          <LibraryAttribution />

          <div className="flex margin-bottom-1">
            <Button onClick={handleLoadSample}>Load Sample JSON</Button>
          </div>

          <div
            className={`${isMobile ? 'flex flex-direction-column' : 'grid'}`}
            style={!isMobile ? { gridTemplateColumns: '1fr 1fr', gap: '1.5rem' } : {}}
          >
            <div className="flex flex-direction-column margin-bottom-1">
              <label className="margin-bottom-half">First JSON:</label>
              <textarea
                className={`code-textarea ${isDark ? 'dark' : ''}`}
                style={{
                  minHeight: '400px',
                  fontFamily: 'monospace',
                }}
                value={json1}
                onChange={(e) => setJson1(e.target.value)}
                placeholder="Paste your first JSON here"
              />
            </div>

            <div className="flex flex-direction-column margin-bottom-1">
              <label className="margin-bottom-half">Second JSON:</label>
              <textarea
                className={`code-textarea ${isDark ? 'dark' : ''}`}
                style={{
                  minHeight: '400px',
                  fontFamily: 'monospace',
                }}
                value={json2}
                onChange={(e) => setJson2(e.target.value)}
                placeholder="Paste your second JSON here"
              />
            </div>
          </div>

          <section>
            <div className="note-container">
              <h3>Configuration Options</h3>
              <div
                className="grid"
                style={{
                  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                  gap: '1rem',
                }}
              >
                <div className="form-group">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={strictTypeChecking}
                      onChange={(e) => setStrictTypeChecking(e.target.checked)}
                    />
                    <span className="checkbox-text">Strict Type Checking</span>
                  </label>
                  <small className="form-hint">
                    Enforce exact type matching (number vs string, etc.)
                  </small>
                </div>

                <div className="form-group">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={ignoreExtraKeys}
                      onChange={(e) => setIgnoreExtraKeys(e.target.checked)}
                    />
                    <span className="checkbox-text">Ignore Extra Keys</span>
                  </label>
                  <small className="form-hint">
                    Don&apos;t count extra fields in the second JSON as mismatches
                  </small>
                </div>

                <div className="form-group">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={useRegex}
                      onChange={(e) => setUseRegex(e.target.checked)}
                    />
                    <span className="checkbox-text">Use Regex Validation</span>
                  </label>
                  <small className="form-hint">
                    Validate field values with regular expressions
                  </small>
                </div>

                <div className="form-group">
                  <label>Keys to Ignore (comma-separated):</label>
                  <input
                    type="text"
                    className="form-input"
                    value={ignoreKeys}
                    onChange={(e) => setIgnoreKeys(e.target.value)}
                    placeholder="e.g., timestamp,id,createdAt"
                  />
                  <small className="form-hint">
                    Fields that should be excluded from comparison
                  </small>
                </div>

                <div className="form-group">
                  <label>Equivalent Values (JSON format):</label>
                  <input
                    type="text"
                    className="form-input"
                    value={equivalentValues}
                    onChange={(e) => setEquivalentValues(e.target.value)}
                    placeholder='e.g., {"numericValues": [100, "100", 100.0]}'
                  />
                  <small className="form-hint">Values that should be considered equivalent</small>
                </div>

                <div className="form-group">
                  <label>Regex Patterns (JSON format):</label>
                  <input
                    type="text"
                    className="form-input"
                    value={regexPatterns}
                    onChange={(e) => setRegexPatterns(e.target.value)}
                    placeholder='e.g., {"email": "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}'
                    disabled={!useRegex}
                  />
                  <small className="form-hint">
                    Regular expressions to validate specific fields
                  </small>
                </div>
              </div>
            </div>
          </section>

          <div className="flex flex-center margin-top-1 margin-bottom-1">
            <Button onClick={handleCompare}>Compare</Button>
          </div>

          {showResults && (
            <section className="section">
              <h3>Comparison Results</h3>

              {compareError && (
                <div>
                  <strong>Error:</strong> {compareError}
                </div>
              )}

              {results && (
                <>
                  <div className="flex" style={{ gap: '1rem', marginBottom: '1rem' }}>
                    <ButtonLink simpleLink onClick={handleCopyResults}>
                      Copy Results
                    </ButtonLink>
                    <ButtonLink simpleLink onClick={handleDownloadResults}>
                      Download Results
                    </ButtonLink>
                  </div>

                  <div>
                    <h3>Summary</h3>
                    <div>
                      {results.matchPercentage !== undefined ? (
                        <p>
                          Match Percentage: <strong>{results.matchPercentage.toFixed(2)}%</strong>
                        </p>
                      ) : (
                        <p>
                          Match Percentage: <strong>N/A</strong>
                        </p>
                      )}
                      <p>
                        Matched Keys:{' '}
                        <span className="green">{results.matchedKeys?.length || 0}</span>
                      </p>
                      <p>
                        Unmatched Keys:{' '}
                        <span className="pink">{results.unmatchedKeys?.length || 0}</span>
                      </p>
                      <p>
                        Type Mismatches:{' '}
                        <span className="pink">
                          {results.typeMismatches ? Object.keys(results.typeMismatches).length : 0}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div>
                    <h3>Matched Keys</h3>
                    {renderMatchedKeys()}
                  </div>

                  <div>
                    <h3>Unmatched Keys</h3>
                    {renderUnmatchedKeys()}
                  </div>

                  <div>
                    <h3>Type Mismatches</h3>
                    {renderTypeMismatches()}
                  </div>

                  {results.regexValidationFailures &&
                    Object.keys(results.regexValidationFailures).length > 0 && (
                      <div className="section note">
                        <h3>Regex Validation Failures</h3>
                        <ul>
                          {Object.entries(results.regexValidationFailures).map(([key, value]) => (
                            <li key={key}>
                              <span className="pink">
                                {key}: {value}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </>
              )}
            </section>
          )}
        </div>
      </Layout>
    </Suspense>
  )
}

export default JsonCompareTool
